import React from "react";
import "../styles/logistics.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import CountUp from "../components/CountUp";


const Logistics = () => {
    const [isOpen, setIsOpen] = useState(false); // State to manage modal visibility
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      number: '',
      pickupLocation: '',
      dropOff: '',
    });
  
    // Handle input changes
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      // Basic form validation
      if (!formData.name || !formData.email || !formData.number || !formData.pickupLocation || !formData.dropOff) {
        alert('Please fill in all fields.');
        return;
      }
      // Display an alert on successful submission
      alert('Thank you! A quote will be sent to your Email.');
      setIsOpen(false); // Close the modal after submission
      setFormData({
        name: '',
        email: '',
        number: '',
        pickupLocation: '',
        dropOff: '',
      }); // Reset form fields
    };
  return (
    <>
      <section className="logi-inner-con ">
        <div
          className="text text-white  text-center flex
      flex-col items-center justify-center capitalize pb-24"
        >
          <p className="mb-6 xl:text-xl lg:text-2xl leading-snug pt-10 md:text-xl text-xl">
            welcome to Tricord logistics
          </p>
          <h2 className="xl:text-7xl xl:w-[70%] lg:text-7xl md:text-5xl text-3xl">
            Delivering Excellence in logistics
          </h2>
          <p className="xl:text-2xl mt-5 lg:text-2xl">
            Your trusted partner in Global supply chain
          </p>
          <Link
            to="/t-form"
            className="bg-[#277A1B] py-4 px-6 rounded mt-5 mb-16 lg:text-2xl md:text-xl text-xl"
          >
            Book a truck
          </Link>
        </div>
      </section>

      <section className="service-sec">
        <div className="service-info-box md:flex md:gap-5 md:justify-between gap-10 pt-[100px] translate-y-[-150px] ">
          <div className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-lg border-[var(--primary-green)] md:w-[40%]  sm:w-[100%] py-10 ">
            <h2 className="lg:text-5xl text-4xl"> <CountUp start={0} end={1000} duration={3000} /></h2>
            <p className="text-center lg:text-xl">Customers Nationwide</p>
          </div>

          <div className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-lg border-[var(--primary-green)] md:w-[40%]  sm:w-[100%] py-10 ">
            <h2 className="lg:text-5xl text-4xl"><CountUp start={0} end={95} duration={3000} />%</h2>
            <p className="text-center lg:text-xl">Satisfaction Rate</p>
          </div>
          <div className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-lg border-[var(--primary-green)]  md:w-[40%] sm:w-[100%] py-10">
            <h2 className="lg:text-5xl text-4xl"><CountUp start={0} end={5000} duration={3000} /></h2>
            <p className="text-center lg:text-xl">deliveries</p>
          </div>
          <div className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-lg border-[var(--primary-green)]  md:w-[40%] sm:w-[100%] py-10">
            <h2 className="lg:text-5xl text-4xl"><CountUp start={0} end={500} duration={3000}/>+</h2>
            <p className="text-center lg:text-xl">Farm produce</p>
          </div>
        </div>

        <div className="tricord-food-con xl:flex gap-8 pb-36">
          <div className="food-text w-[100%] font-normal  text-[#0B2C07] ">
            <h2 className="xl:text-5xl md:text-6xl text-3xl  xl:leading-[4rem] mb-5 capitalize font-semibold ">
             Tricord logistics
            </h2>
            <p className="xl:text-2xl  text-xl md:text-xl md:leading-[3rem] xl:leading-[3rem] leading-[3rem] mb-6">
            Tricord Logistics ensures the safe and secure transportation of fragile, oversized, and high-value items through tailored solutions. Using protective packaging, specialized equipment, and enhanced security measures, they safeguard goods like machinery, artwork, and luxury items during transit. Their team of trained professionals prioritizes care, precision, and reliability, delivering customized transport services that meet unique client needs.
            </p>
            {/* <div className="core-value">
              <h3 className="text-3xl mb-5">Core Value</h3>
              <div className="flex  gap-5 items-center mb-5">
                <img src="../Images/tick.svg" alt="" />
                <p className=" md:text-2xl text-xl ">Integrity</p>
              </div>
              <div className="flex  gap-5 items-center mb-5">
                <img src="../Images/tick.svg" alt="" />
                <p className="md:text-2xl text-xl ">Customer Focus</p>
              </div>
              <div className="flex  gap-5 items-center mb-5">
                <img src="../Images/tick.svg" alt="" />
                <p className=" md:text-2xl text-xl ">Safety</p>
              </div>
              
            </div> */}
            
          </div>
          <img
            src="Images/truck.jpg"
            alt=""
            className="xl:w-[70%]  w-[100%] xl:h-[550px] h-[500px] rounded-[20px]"
            
            
          />
        </div>

        <div className="mission-statement xl:w-[70%] bg-[#F8ECC8] md:px-10 md:py-16 xl:ml-64 z-40 w-[100%] rounded-[20px] px-6 py-6 ">
          <img src="Images/mission-star.svg" alt=""/>
          <h2 className="xl:text-4xl mt-5 text-3xl">Mission Statement</h2>

          <p className="xl:text-2xl mt-4 leading-loose z-40 text-xl">
            At Tricord Global Resources, our commitment to efficiency,
            reliability, and exceptional customer service drives everything we
            do. We pride ourselves on delivering solutions that meet the highest
            standards while fostering trust and satisfaction among our partners
            and customers. Our dedication ensures that every interaction,
            product, and service is handled with precision, dependability, and a
            focus on exceeding expectations.
          </p>
        </div>

       

        <div className="industries-served   pb-16 pt-36">
          <h2 className="xl:text-5xl capitalize mb-5 text-3xl">industries served</h2>
          <p className="xl:text-2xl mb-5 text-2xl leading-[3rem]">
            We understand the unique challenges of each industry and tailor our
            logistics solutions to meet them.
          </p>
          <div className="indus-con flex flex-wrap w-[100%]  gap-9">
          <div className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]">
              <img src="Images/star.svg" alt="" className="mb-5" />
              <h2 className="text-2xl mb-3">E-commerce</h2>
              <p className=" text-xl">
              Streamlining packaging and logistics to ensure fast, safe delivery.
              </p>
            </div>
           
            <div className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]">
              <img src="Images/star.svg" alt=""  className="mb-5"/>
              <h2 className="text-2xl mb-3">Healthcare -</h2>
              <p className=" text-xl">
              Providing reliable transport and secure packaging for medical supplies
              </p>
            </div>
            <div className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]">
              <img src="Images/star.svg" alt="" className="mb-5"/>
              <h2 className="text-2xl mb-3">Agriculture -</h2>
              <p className=" text-xl">
              Supporting farmers with depots, processing, and efficient export solutions
              </p>
            </div>
            <div className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]">
              <img src="Images/star.svg" alt=""className="mb-5" />
              <h2 className="text-2xl mb-3">Automotive -</h2>
              <p className=" text-xl">
              Delivering secure, timely transport for parts and components.
              </p>
            </div>
            <div className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]">
              <img src="Images/star.svg" alt="" className="mb-5" />
              <h2 className="text-2xl mb-3">Manufacturing -</h2>
              <p className=" text-xl">
              Providing scalable solutions for industrial packaging and logistics.
              </p>
            </div>
            <div className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] px-10 rounded-[25px]">
              <img src="Images/star.svg" alt="" className="mb-5" />
              <h2 className="text-2xl mb-3">Retail –</h2>
              <p className="text-xl">
              Offering eco-friendly packaging and efficient supply chain management
              </p>
            </div>
            
          </div>
        </div>

        
      </section>
      <section>
      <div className="partner w-[100%]">
            <h1 className="md:text-4xl xl:w-[35%] mb-5 font-semibold leading-[3rem] mt-20 text-3xl">Partner with Us for Quality and Reliability </h1>
            <p className="text-xl xl:w-[80%] mb-5 leading-[2rem]">Whether you need eco-friendly packaging, logistics support, or access to fresh local food products, Tricord Global Resources is here to serve you. Take the next step and connect with us today!</p>
            <h3 className="md:text-3xl  text-2xl font-semibold ">Ready to Work with Us?</h3>
            <p className="text-xl xl:w-[70%] mt-3 mb-10">Request a quote or reach out to learn more about our services. Your journey to quality and sustainability starts here.</p>
            <div className="partner-btns flex items-center gap-4 pb-28">

                {/* Button to open the form modal */}
      <button
        onClick={() => setIsOpen(true)}
        className="px-7 py-4 bg-[#277A1B] rounded-[20px] text-white md:text-xl text-[12px]"
      >
        Request a Quote
      </button>
               
                <Link className=" rounded-[20px] border-2 px-6 py-3 border-[#277A1B] text-[#277A1B] md:text-xl text-[12px]" to='mailto:contact@tricordglobal.com.ng'>Contact Us</Link>
                <div className="flex flex-col items-center justify-center ">
    

      {/* Modal Form */}
      {isOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 md:w-full max-w-md w-[80%]">
            <h2 className="text-2xl font-bold mb-4">Request a Quote</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4 input-con">
                <label className="block  text-sm font-bold mb-2" htmlFor="name">
                  Name:
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="mb-4 input-con">
                <label className="block  text-sm font-bold mb-2" htmlFor="email">
                  Email:
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="mb-4 input-con">
                <label className="block text-sm font-bold mb-2" htmlFor="number">
                  Phone Number:
                </label>
                <input
                  type="text"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="mb-4 input-con">
                <label className="block  text-sm font-bold mb-2" htmlFor="pickupLocation">
                  Pickup Location:
                </label>
                <input
                  type="text"
                  name="pickupLocation"
                  value={formData.pickupLocation}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 tleading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="mb-6 input-con">
                <label className="block text-sm font-bold mb-2" htmlFor="dropOff">
                  Drop-Off Location:
                </label>
                <input
                  type="text"
                  name="dropOff"
                  value={formData.dropOff}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-[#277A1B] hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>

            </div>
        </div>
      </section>
      
    </>
  );
};

export default Logistics;

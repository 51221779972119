import React from 'react'
import '../styles/hero.css'
import Header from './Header'
import CountUp from "./CountUp";

const Hero = () => {
  return (
    <>
   <section className="hero-con bg-[rgb(248,250,240)] ">
  
    <div className="hero-inner-con xl:flex py-24 md:gap-24 text-white" data-aos="fade-right">
       <div className="hero-text-con xl:w-3/5	sm:w-full text-left">
          <p className='text-[var(--text-green)] mb-3 md:text-[20px]' > Welcome to Tricord Global Resources</p>
          <h2 className='xl:text-5xl  xl:leading-[75px] md:text-6xl sm:text-4xl md:leading-[85px] sm:leading-[70px]  md:leading-[70px] mb-3 font-medium text-2xl' >Supporting You With Sustainable <span  className='text-[var(--text-green)]'>Logistics</span>, <span className='text-[var(--text-green)]'>Packaging</span>,  and <span className='text-[var(--text-green)]'>Organic Food,</span> Solutions</h2>
          <p className='md:text-[25px] md:leading-10 xl:text-[20px]'>We are dedicated to delivering excellence across the supply chain, ensuring that every step from sourcing to storage is handled with care and quality in mind. Join us in promoting sustainable and organic solutions for a healthier future</p>
       </div>
     
    </div>
   
  
   </section>
   <section className='bg-[#F9FAF0]'>
   <div className="service-info-box md:flex md:gap-5 md:justify-between gap-10 pt-[100px] translate-y-[-150px] ">
          <div className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-[15px] border-[var(--primary-green)] md:w-[40%]  sm:w-[100%] py-10 " data-aos="zoom-in">
            <h2 className="xl:text-5xl  lg:text-4xl text-4xl"> <CountUp start={0} end={1000} duration={3000} /></h2>
            <p className="text-center xl:text-xl ">Customers Nationwide</p>
          </div>

          <div className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-[15px] border-[var(--primary-green)] md:w-[40%]  sm:w-[100%] py-10 " data-aos="zoom-in">
            <h2 className="xl:text-5xl text-4xl  lg:text-4xl"><CountUp start={0} end={95} duration={3000} />%</h2>
            <p className="text-center xl:text-xl">Satisfaction Rate</p>
          </div>
          <div className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-[15px] border-[var(--primary-green)]  md:w-[40%] sm:w-[100%] py-10" data-aos="zoom-in">
            <h2 className="xl:text-5xl text-4xl  lg:text-4xl"><CountUp start={0} end={5000} duration={3000} /></h2>
            <p className="text-center lg:text-xl">deliveries</p>
          </div>
          <div className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-[15px] border-[var(--primary-green)]  md:w-[40%] sm:w-[100%] py-10" data-aos="zoom-in">
            <h2 className="xl:text-5xl text-4xl  lg:text-4xl"><CountUp start={0} end={500} duration={3000}/>+</h2>
            <p className="text-center xl:text-xl">Farm produce</p>
          </div>
        </div>
   </section>
  
   
      
    </>
  )
}

export default Hero

import React, { useState, useEffect } from "react";

const CountUp = ({ start = 0, end = 100, duration = 2000 }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    // Calculate the time interval for each increment
    const increment = (end - start) / (duration / 10);

    const timer = setInterval(() => {
      setCount((prev) => {
        if (prev + increment >= end) {
          clearInterval(timer);
          return end;
        }
        return prev + increment;
      });
    }, 10); // Update every 10ms

    return () => clearInterval(timer); // Cleanup on unmount
  }, [start, end, duration]);

  return (
    <span>
      {Math.round(count)} {/* Rounds to avoid decimal issues */}
    </span>
  );
};

export default CountUp;

import { useState, useEffect } from "react";
import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10); // Change this threshold as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`header-con  ${isScrolled ? "fixed top-0 w-full shadow-lg z-30 text-black bg-white" : "relative"}`}>
      <section className="header-section flex w-full justify-between items-center z-10 text-dark  transition duration-300 ease-in-out">
        <Link to="/" className="logo flex items-center z-30">
          <img src="Images/logo.svg" alt="Logo" width={200} height={100} />
        </Link>

        {/* Navigation Menu */}

        <div
          className={`nav-list ${
            isOpen ? "flex" : "hidden "
          } sm:flex sm:flex-row sm:items-center fixed sm:relative left-0 top-0 h-full w-[60%] sm:w-auto bg-[#0B2C07] sm:bg-transparent p-2 sm:p-0 pt-24 z-10`}
        >
          <ul className="flex flex-col sm:flex-row sm:space-x-6 text-xl w-full sm:w-aut0">
            <li className="text-white">
              <Link
                to="/"
                className=  "text-dark text-xl  block text-lg font-[var(--main-font)] py-2 sm:py-0 sm:text-black "
              >
                About Us
              </Link>
            </li>
            <li className="text-white">
              <a
                href="#gallery"
                className="text-dark text-xl text-white block text-lg font-[var(--main-font)] py-2 sm:py-0 sm:text-black"
              >
                Services
              </a>
            </li>
            <li className="text-white ">
              <a
                href="#contact"
                className= "text-dark text-xl text-white block text-lg font-[var(--main-font)] py-2 sm:py-0 sm:text-black"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>

        {/* Hamburger Menu for Mobile */}
        <div onClick={toggleMenu} className="sm:hidden z-10 cursor-pointer">
          <div className="flex flex-col justify-center items-center w-8 h-full space-y-1">
            <span
              className={`block h-0.5 w-full bg-black transform transition duration-300 ${
                isOpen ? "rotate-45 translate-y-1.5" : ""
              }`}
            ></span>
            <span
              className={`block h-0.5 w-full bg-black transition duration-300 ${
                isOpen ? "opacity-0" : ""
              }`}
            ></span>
            <span
              className={`block h-0.5 w-full bg-black transform transition duration-300 ${
                isOpen ? "-rotate-45 -translate-y-1.5" : ""
              }`}
            ></span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;

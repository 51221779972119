import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/food.css'

const Food = () => {
  return (
    <>
      <section className="food-inner-con ">
        <div
          className="text text-black  text-center flex
      flex-col items-center justify-center capitalize pb-24 text-white"
        >
          <p className="mb-6 xl:text-xl lg:text-2xl leading-snug pt-10 md:text-xl text-2xl ">
            welcome to Tricord Foods
          </p>
          <h2 className="xl:text-5xl xl:w-[70%] lg:text-5xl md:text-5xl text-4xl">
          Wholesome Goodness, Naturally Processed
          </h2>
          <p className="xl:text-xl mt-10 lg:text-2xl text-black xl:w-[80%]">
          At Tricord Food, we are committed to providing high-quality, naturally processed food products that nourish your body and satisfy your taste buds. While we aim to expand our product line, we are proud to introduce our flagship offering—premium plantain-based products.
          </p>
          <Link
            to="/t-form"
            className="bg-[#277A1B] py-4 px-6 rounded mt-5 mb-16 lg:text-xl md:text-xl text-2xl"
          >
            Shop products
          </Link>
        </div>
      </section>
      
    </>
  )
}

export default Food

import React from "react";
import { Link } from "react-router-dom";
import "../styles/services.css";



const Services = () => {
  return (
    <>
      <div className="main-bg">
        <section className="our-service-section pt-32">
         
          <div className="main-service-con">
            <div className="service-header flex flex-col justify-center items-center text-[#0B2C07] font-semibold text-center" data-aos="zoom-in">
              <h1 className="xl:text-6xl mb-5 text-4xl md:text-6xl" >
                Our Services
              </h1>
              <p className="xl:text-xl texl-sm md:text-2xl mb-5">
                Supporting You with Sustainable Logistics, Packaging, and
                Organic Food Solutions
              </p>[]
            </div>
            <div className="tricord-food-con xl:flex gap-8 mt-[150px] ">
              <img
                src="Images/tricordplantain.jpeg"
                alt=""
                className="xl:w-[50%]  w-[100%] xl:h-[500px] h-[500px] rounded-[30px]"
                data-aos="zoom-in"
              />
              <div className="food-text w-[100%] mt-16 font-normal  text-[#0B2C07] "data-aos="fade-left">
                <h2 className="xl:text-5xl md:text-6xl text-3xl font-normal xl:leading-[4rem] font-semibold">
                  Tricord Food–Local Production for Local Needs
                </h2>
                <p className="xl:text-xl md:text-2xl md:leading-[3rem] xl:leading-[2rem] mb-5">
                  Through Tricord Food, we produce a range of high-quality food
                  products tailored for local consumption. From plantain chips
                  and plantain flour to yam flour, custard, garri, and various
                  pasta options, we bring nutritious, locally sourced products
                  to our community, contributing to food security and local
                  agriculture.
                </p>
                <div className="flex items-center gap-4">
                  {" "}
                  <Link
                    to="food"
                    className="xl:text-2xl md:text-2xl text-[#2F9B1E]"
                  >
                    see more{" "}
                  </Link>
                  <Link to="food">
                    <img src="Images/union.svg" alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="tricord-food-con xl:flex gap-8 mt-[200px] ">
              <div className="food-text w-[100%] mt-16 font-normal  text-[#0B2C07]  " data-aos="fade-right">
                <h2 className="xl:text-5xl md:text-6xl text-3xl font-normal xl:leading-[4rem] mb-5 font-semibold">
                  Tricord packaging
                </h2>
                <p className="xl:text-xl md:text-2xl md:leading-[3rem] xl:leading-[2rem] mb-5">
                  Elevate your business with our eco-friendly packaging
                  solutions. Tricord Branding specializes in innovative,
                  sustainable packaging options, including shopping bags, paper
                  bags, and biodegradable materials, tailored to meet your
                  business needs while promoting environmental responsibility.
                </p>
                <div className="flex items-center gap-4 mb-4">
                  {" "}
                  <Link
                    to="packaging"
                    className="xl:text-2xl md:text-2xl text-[#2F9B1E]"
                  >
                    see more{" "}
                  </Link>
                  <Link to="packaging">
                    <img src="Images/union.svg" alt="" />
                  </Link>
                </div>
              </div>
              <img
                src="Images/tricord-packaging.webp"
                alt=""
                className="xl:w-[50%]  w-[100%] 
                xl:h-[500px] h-[500px] rounded-[30px]"
                data-aos="zoom-in"
              />
            </div>
            <div className="tricord-food-con xl:flex gap-8 mt-28 ">
              <img
                src="Images/tricorddepot.webp"
                alt=""
                className=" xl:w-[50%]  w-[100%] 
                xl:h-[500px] h-[300px] rounded-[30px]"
                data-aos="zoom-in"
              />
              <div className="food-text w-[100%] mt-16 font-normal  text-[#0B2C07] " data-aos="fade-left">
                <h2 className="xl:text-5xl md:text-6xl text-3xl font-normal xl:leading-[4rem] mb-5 font-semibold">
                  {" "}
                  Tricord Depot{" "}
                </h2>
                <p className="xl:text-xl md:text-2xl md:leading-[3rem] xl:leading-[2rem] mb-5">
                  Our depot goes beyond storage. It’s a hub of opportunity where
                  we buy produce directly from farmers, process it to meet
                  international standards, and export it to global markets.
                  Through small loan access, we help farmers expand their
                  operations, ensuring a stable income and a path toward growth.
                  At Tricord, we are proud to support farmers by being the
                  offtaker they can rely on.
                </p>
                <div className="flex items-center gap-2 mb-4" >
                  {" "}
                  <Link
                    to="/depot"
                    className="xl:text-2xl md:text-2xl text-[#2F9B1E]"
                  >
                    see more{" "}
                  </Link>
                  <Link to='/depot'>
                    <img src="Images/union.svg" alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="tricord-food-con xl:flex gap-8 mt-[200px]  pb-28">
              <div className="food-text w-[100%] mt-16 font-normal  text-[#0B2C07]" data-aos="fade-right">
                <h2 className="xl:text-5xl md:text-6xl text-3xl font-normal xl:leading-[4rem] mb-5 font-semibold">
                  Tricord Logistics
                </h2>
                <p className="xl:text-xl md:text-2xl md:leading-[3rem] xl:leading-[2rem] mb-5">
                  Efficient and reliable logistics solutions to ensure timely
                  delivery and transportation of products. Our logistics team is
                  dedicated to optimizing the supply chain, reducing costs, and
                  enhancing efficiency for all our partners.
                </p>
                <div className="flex items-center gap-2 mb-4">
                  {" "}
                  <Link
                    to="logi"
                    className="xl:text-2xl md:text-2xl text-[#2F9B1E]"
                  >
                    see more{" "}
                  </Link>
                  <Link to="logi">
                    <img src="Images/union.svg" alt="" />
                  </Link>
                </div>
              </div>
              <img
                src="Images/truck.jpg"
                alt=""
                className="xl:w-[50%]  w-[100%] xl:h-[500px] h-[500px] rounded-[30px]"
                data-aos="zoom-in"
              />
            </div>
          </div>
          <div className="partner w-[100%]" data-aos="fade-right">
            <h1 className="md:text-4xl xl:w-[35%] mb-5 font-semibold leading-[3rem] mt-48 text-3xl">Partner with Us for Growth and Excellence </h1>
            <p className="text-xl xl:w-[80%] mb-5 leading-[2rem]">Whether you're a farmer seeking a reliable offtaker, a business in need of top-quality processed farm produce, or an exporter aiming for international standards, Tricord Depot is your trusted partner. Together, we bridge the gap between farms and global markets.</p>
            <h3 className="md:text-3xl  text-2xl font-semibold ">Ready to Work with Us?</h3>
            <p className="text-xl xl:w-[70%] mt-3 mb-10">Reach out to discuss how we can support your farming or business goals. Whether it's processing, financing, or exporting, your journey to success starts with Tricord Depot. Let’s grow together!</p>
            <div className="partner-btns flex items-center gap-4 pb-28">

  
               
                <Link className=" rounded-[20px] border-2 px-6 py-3 border-[#277A1B] text-[#277A1B] md:text-xl text-[12px]" to='mailto:contact@tricordglobal.com.ng' >Contact Us</Link>
                <div className="flex flex-col items-center justify-center ">
    

   
  
    </div>

            </div>
        </div>
        </section>
      </div>
    </>
  );
};

export default Services;

import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/packaging.css'

const Packaging = () => {
  return (
    <>
    <section className="packaging-inner-con">
        <div
          className="text text-dark  text-center flex
      flex-col items-center justify-center capitalize pb-20"
        >
          <p className="mb-6 xl:text-xl lg:text-2xl leading-snug pt-10 md:text-xl text-2xl">
            welcome to Tricord Packaging
          </p>
          <h2 className="xl:text-5xl xl:w-[70%] lg:text-5xl md:text-5xl text-4xl text-[#277A1B]">
          Innovative Packaging Solutions for a Sustainable Future
          </h2>
          <p className="xl:text-xl mt-10 lg:text-xl xl:w-[80%]">
          At Tricord Packaging, we specialize in producing high-quality, eco-friendly packaging that enhances your brand’s identity while promoting sustainability. From shopping bags to biodegradable alternatives, we provide packaging solutions that align with modern environmental goals and consumer expectations.
          </p>
          <Link
            to="/t-form"
            className="bg-[#277A1B] py-4 px-6 rounded mt-5 mb-16 lg:text-xl md:text-xl text-xl text-white"
          >
           coming soon
          </Link>
        </div>
      </section>
      
    </>
  )
}

export default Packaging

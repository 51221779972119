import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from "react";
import Layout from './components/Layout';
import Logistics from './pages/Logistics'
import Home from './pages/Home'
import FormPage from './pages/FormPage';
import Food from './pages/Food';
import Packaging from './pages/Packaging';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import Depot from './pages/Depot';


function App() {
  useEffect(() => {
    AOS.init({
      once: true, 
      duration: 1500, 
    });
  }, []);
  return (
  <>
    <Router>
        <Routes>
          <Route path="/" element={<Layout/>} >
          <Route path='/' element={<Home/>}/>
          <Route path='/logi' element={<Logistics/>}/>
          <Route path='/t-form' element={<FormPage/>}/>
          <Route path='/food' element={<Food/>}/>
          <Route path='/packaging' element={<Packaging/>}/>
          <Route path='/depot' element={<Depot/>}/>
           

            </Route>
          
         
          




        </Routes>
      </Router>
  </>
  );
}

export default App;
